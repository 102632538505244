import { Option } from '@utils/types/common-types';
import { ContractType, PriceType } from '@utils/types/contract';

const OPTIONS_MAP: Record<PriceType, Option<PriceType>> = {
  fixed: { label: 'sales_management.price.fix', value: 'fixed' },
  spot: { label: 'sales_management.price.spot', value: 'spot' },
  imbalance_pos: { label: 'sales_management.price.imbalance_pos', value: 'imbalance_pos' },
  indexed: { label: 'sales_management.price.indexed', value: 'indexed' },
  prefcapa: { label: 'PREFCAPA', value: 'prefcapa' },
  m0: { label: 'sales_management.price.m0_swap', value: 'm0' },
};

export const CONTRACT_OPTIONS_MAP: Partial<Record<ContractType, Option<PriceType>[]>> = {
  ContractSwap: [OPTIONS_MAP.spot, OPTIONS_MAP.m0],
  ContractSwapPl: [OPTIONS_MAP.spot, OPTIONS_MAP.m0],
};
