import { Option } from '@utils/types/common-types';
import { ContractType, PriceType } from '@utils/types/contract';

const OPTIONS_MAP: Record<PriceType | 'no-option', Option<PriceType | null>> = {
  'no-option': { label: 'common.none', value: null },
  fixed: { label: 'sales_management.price.fix', value: 'fixed' },
  spot: { label: 'sales_management.price.spot', value: 'spot' },
  imbalance_pos: { label: 'sales_management.price.imbalance_pos', value: 'imbalance_pos' },
  indexed: { label: 'sales_management.price.indexed', value: 'indexed' },
  prefcapa: { label: 'PREFCAPA', value: 'prefcapa' },
  m0: { label: 'sales_management.price.m0', value: 'm0' },
};

export const CONTRACT_OPTIONS_MAP: Partial<Record<ContractType, Option<PriceType | null>[]>> = {
  ContractCapa: [OPTIONS_MAP.fixed, OPTIONS_MAP.prefcapa],
  ContractCr: [OPTIONS_MAP.fixed, OPTIONS_MAP.imbalance_pos, OPTIONS_MAP.spot, OPTIONS_MAP.m0],
  ContractSoa: [OPTIONS_MAP.fixed, OPTIONS_MAP.spot, OPTIONS_MAP.imbalance_pos, OPTIONS_MAP.indexed],
  ContractCppa: [OPTIONS_MAP.fixed, OPTIONS_MAP.spot, OPTIONS_MAP.imbalance_pos, OPTIONS_MAP.indexed],
  ContractCapaProxy: [OPTIONS_MAP.fixed, OPTIONS_MAP.indexed],
  ContractSwap: [OPTIONS_MAP.fixed, OPTIONS_MAP.indexed],
  ContractSwapPl: [OPTIONS_MAP.fixed, OPTIONS_MAP.indexed],
  ContractGo: [OPTIONS_MAP.fixed, OPTIONS_MAP.indexed],
  'ContractGo-LIGHT': [OPTIONS_MAP['no-option'], OPTIONS_MAP.fixed],
  'ContractSoa-LIGHT': [OPTIONS_MAP['no-option'], OPTIONS_MAP.fixed],
};
