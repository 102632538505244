import { ContractFormField } from '@pages/Contracts/Contract/contracts.types';

export const SWAPPlFields: ContractFormField[] = [  // TODO: Update this to the actual fields
  { name: 'p_max', configurable: true },
  { name: 'contract_sub_periods_attributes.0.dates', required: true },
  {
    name: 'contract_sub_periods_attributes.0.extra_params.swap_price_type',
    required: true,
    info: { title: 'sales_management.swap_price_type', text: 'sales_management.swap_spot_info' },
  },
  {
    name: 'contract_sub_periods_attributes.0.extra_params.swap_fixed_price',
    required: true,
    rules: { max: 10000 },
    info: { title: 'sales_management.swap_fixed_price', text: 'sales_management.swap_spot_info' },
  },
  { name: 'contract_sub_periods_attributes.0.price_type', required: false },
  {
    name: 'rate_change',
    required: false,
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' }],
  },
  {
    name: 'index_formula',
    required: false,
    onlyForFieldValues: [{ field: 'contract_sub_periods_attributes.0.price_type', value: 'indexed' }],
  },
  {
    name: 'contract_sub_periods_attributes.0.extra_params.swap_scale_factor',
    required: true,
    rules: { min: 1, max: 1 },
    info: { title: 'sales_management.swap_scale_factor', text: 'sales_management.swap_spot_info' },
  },

  { name: 'contract_sub_periods_attributes.0.neg_price_rule' },

  { name: 'external_pdf_link', required: false, permission: 'display:non_engie' },
  { name: 'sent_to_remit' },
];
